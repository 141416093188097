function carOverlay(point, { width, height } = { width: 50, height: 28 }) {
    this._width = width ? width : 50
    this._height = height ? height : 50
    this._position = point
}

carOverlay.prototype = new BMapGL.Overlay()
carOverlay.prototype.initialize = function (map) {
    this._map = map;
    // let div = document.createElement('div')
    let img = document.createElement('img')
    img.src = '/imgs/transport/work.png'
    img.style.width = this._width + 'px'
    img.style.height = this._height + 'px'
    // div.style.width = this._width + 'px'
    // div.style.height = this._height + 'px'
    img.style.position = 'absolute'
    this._img = img;
    map.getPanes().markerPane.appendChild(img)
    return img
}
carOverlay.prototype.draw = function () {
    let position = this._map.pointToOverlayPixel(this._position)
    this._img.style.left = position.x - this._width / 2 + 'px'
    this._img.style.top = position.y - this._height / 2 + 'px'
}

export default carOverlay